import React from 'react'
import { Layout, Section, SectionTitle, Seo } from '../components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'

const NotFoundPage = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <Layout>
            <Section>
                <Seo title={t('404_seo_title')} />

                <div
                    style={{
                        display: 'flex',
                        height: '40vh',
                        alignItems: 'center',
                    }}
                >
                    <SectionTitle style={{ margin: 'auto' }}>
                        {t('404_not_found')}
                    </SectionTitle>
                </div>
            </Section>
        </Layout>
    )
}

export default injectIntl(NotFoundPage)
